import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import ToggleTipPositionImage from '@images/components/toggle-tip/1_toggletips-position.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const ExampleBox = makeShortcode("ExampleBox");
const ToggleTipLabelExample = makeShortcode("ToggleTipLabelExample");
const ToggleTipParagraphExample = makeShortcode("ToggleTipParagraphExample");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const DoToggleTipExample1 = makeShortcode("DoToggleTipExample1");
const Dont = makeShortcode("Dont");
const DontToggleTipExample1 = makeShortcode("DontToggleTipExample1");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-toggletips"
            }}>{`How to use toggletips`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-toggletip"
            }}>{`Types of toggletip`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#toggletip-interaction"
            }}>{`Toggletip interaction`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-toggletips",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-toggletips",
        "aria-label": "how to use toggletips permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use toggletips`}</h2>
    <p>{`Toggletips can provide short supporting text to help users understand something on a page. While tooltips can be opened on hover and focus, toggletips are opened with an explicit click or tap.`}</p>
    <p>{`When using a toggletip:`}</p>
    <ul>
      <li parentName="ul">{`Only include supporting information that's not essential for the user to progress`}</li>
      <li parentName="ul">{`Allow the toggletip to be opened by a click, tap or the Enter or Space keys`}</li>
      <li parentName="ul">{`They uses edge detection and will adjust accordingly to the viewport`}</li>
      <li parentName="ul">{`They can be placed at any point within the parent content`}</li>
      <li parentName="ul">{`Only one toggletip can be open at a time`}</li>
      <li parentName="ul">{`Make sure the toggletip icon has an accessible name`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "types-of-toggletip",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-toggletip",
        "aria-label": "types of toggletip permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of toggletip`}</h2>
    <h3>{`Toggletip positioning`}</h3>
    <p>{`Four positioning types are available for a toggletip.`}</p>
    <ImageWithCaption image={ToggleTipPositionImage} type="png" alt="Example of toggletip left/right/up/down positioning" mdxType="ImageWithCaption" />
    <p>{`If a toggletip's position is set in such a way that there is no room to open, it will change tooltip-position accordingly. `}</p>
    <p>{`For example, if the toggletip is on the bottom of the page with a position of bottom, it will be set to top. This works vertically and horizontally.`}</p>
    <h3>{`Toggletip with label`}</h3>
    <p>{`If the content is short and concise you can use the label only content option.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ToggleTipLabelExample mdxType="ToggleTipLabelExample" />
    </ExampleBox>
    <h3>{`Toggletip with paragraph content`}</h3>
    <p>{`Paragraph content is useful for longer descriptions. All the content in a toggletip should be brief (typically a maximum 1–2 sentences) and relate to the context where it was triggered.`}</p>
    <p>{`Toggletip using paragraph content has a maximum width of 244px and a minimum width of 98px. Content that exceeds the maximum width wraps to a new line.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ToggleTipParagraphExample mdxType="ToggleTipParagraphExample" />
    </ExampleBox>
    <p>{`Use a modal instead of a toggletip if the content requires functionality beyond contextual content, such as a filter or other action.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "toggletip-interaction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#toggletip-interaction",
        "aria-label": "toggletip interaction permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Toggletip interaction`}</h2>
    <p>{`A toggletip is triggered by a clickable element, most commonly an icon button.`}</p>
    <p>{`A toggletip can be closed by:`}</p>
    <ul>
      <li parentName="ul">{`Using the esc key`}</li>
      <li parentName="ul">{`Clicking or tapping outside of the toggletip`}</li>
    </ul>
    <hr></hr>
    <h3>{`Content guidelines`}</h3>
    <p>{`If the information is essential to progress, don't hide it in a toggletip. Only use a toggletip for information that is additional and can't fit on the page in some other way.`}</p>
    <p>{`When using a toggletip, keep the content as short as possible. `}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <DoToggleTipExample1 mdxType="DoToggleTipExample1" />
  </Do>
  <Dont center mdxType="Dont">
    <DontToggleTipExample1 mdxType="DontToggleTipExample1" />
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using toggle buttons or give you a
  helping hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      